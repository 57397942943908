<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div>
        <el-row type="flex" justify="space-between">
          <div style="width: 85%">
            <el-row>
              <!-- 消息id -->
              <el-col :span="10">
                <el-form-item :label="$t('title.administrator')">
                  <el-input v-model="form.userName" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="BU">
                  <el-select v-model="form.bu" clearable filterable :placeholder="$t('page.selectPlaceholder')">
                    <el-option
                      v-for="item in buOption"
                      :key="item.id"
                      :label="item.buName"
                      :value="item.buName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24" style="display:flex;height:36px;">
                <el-button type="pa" @click="handleAssign">{{ $t('title.distribute') }}</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
              <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        :label="$t('title.administrator')"
        align="center"
        sortable
      />

      <el-table-column
        prop="bu"
        label="BU"
        align="center"
        sortable
      />
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-button v-if="scope.row.userName === $store.getters.name" type="text" size="small" @click="handleEdit(scope.row)">{{ $t('title.Edit') }}</el-button>
          <!--移除-->
          <el-button v-if="scope.row.userName === $store.getters.name" type="text" size="small" @click="handleRemove(scope.row.id)">{{ $t('title.Remove') }}</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10,20, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog :title="$t('title.assignBU')" :visible.sync="dialogFormVisibleAssign">
      <el-form :model="formAssign">
        <el-form-item :label="$t('title.administrator')">
          <el-input v-model="formAssign.userName" disabled />
        </el-form-item>
        <el-form-item label="BU:">
          <el-radio-group v-model="formAssign.bu">
            <el-radio
              v-for="item in buOption"
              :key="item.id"
              :value="item.buName"
              :label="item.buName"
            >{{ item.buName }}</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAssign = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="submitAssign">{{ $t('title.sure') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { getBuPage, allBaseBu, addBuInsert, buUpdateByIdRemove, buUpdateById } from '@/api/service-management'
export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      TableLoading: false,
      tableDatas: [],
      form: {
        bu: '',
        userName: ''
      },
      buOption: [],
      formAssign: {
        userName: '',
        bu: ''
      },
      dialogFormVisibleAssign: false,
      AssignBuLoding: false

    }
  },

  computed: {
    queryParameter() {
      return Object.assign({}, this.form, this.pager)
    }
  },

  mounted() {
    this._getBuPage()
    this._allBaseBu()
  },
  methods: {
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getBuPage(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this._getBuPage(1)
    },
    // 查询所有BU
    async _allBaseBu() {
      const { datas } = await allBaseBu()
      this.buOption = datas
    },
    // 默认查询
    async _getBuPage(params) {
      try {
        this.TableLoading = true
        const { datas } = await getBuPage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getBuPage(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getBuPage(this.queryParameter)
    },
    // 分配
    handleAssign() {
      this.dialogFormVisibleAssign = true
      const { name } = this.$store.getters
      this.formAssign.userName = name
      console.log(this.$store.getters)
    },
    handleEdit(row) {
      this.dialogFormVisibleAssign = true
      const { userName, bu, id } = row
      this.formAssign = { userName, bu, id }
      this.formAssign.bu = row.bu
      console.log(this.formAssign)
    },
    submitAssign() {
      if (this.formAssign.id) {
        this._buUpdateById({ id: this.formAssign.id, bu: this.formAssign.bu })
      } else {
        const array = [{
          userName: this.formAssign.userName,
          bu: this.formAssign.bu
        }]
        this._addBuInsert(array)
      }
    },
    // 编辑
    async _buUpdateById(row) {
      const { code } = await buUpdateById(row)
      code === 0 ? this.$message.success('编辑成功') : this.$message.error('删除失败')
      this.dialogFormVisibleAssign = false
      this._getBuPage()
      Object.assign(this.formAssign, this.$options.data.call(this).formAssign)
    },
    // 更新状态
    async _addBuInsert(data) {
      try {
        this.AssignBuLoding = true
        const { code, msg } = await addBuInsert(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this._getBuPage()
      } finally {
        this.AssignBuLoding = false
        this.dialogFormVisibleAssign = false
        Object.assign(this.formAssign, this.$options.data.call(this).formAssign)
      }
    },
    async handleRemove(row) {
      const { code } = await buUpdateByIdRemove(row)
      code === 0 ? this.$message.success('删除成功') : this.$message.error('删除失败')
      this.show = 'button'
      this._getBuPage()
    }

  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
